.dropdown .c-select {
    width: 100%;
    font-weight: 300;
    text-align: left;
    color: #a7a7a7;
    border-radius: 2px;
    border: solid 0.5px #a7a7a7;
    cursor: pointer;
}

.dropdown .c-dropdown-menu {
    width: 100%;
    top: 0;
    padding: 0;
    margin: 0;
}

.dropdown .c-dropdown-input {
    width: 100%;
    height: 37px;
    margin-bottom: 0;
    padding: 0 10px;
}

.dropdown .c-dropdown-ul {
    width: 100%;
    padding: 0;
    margin: 0;
}

.dropdown .c-dropdown-item {
    padding: 10px 15px;
    cursor: pointer;
}

.dropdown .c-dropdown-flag {
    margin: 5px 5px 0 0;
}

.dropdown .c-dropdown-menu-overflow {
    max-height: 220px;
    overflow: hidden;
    overflow-y: auto;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #a6a7a8;
}
